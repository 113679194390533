import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import TestResult from "../../components/test-result";


const DNTPage = () => {
  const [dnt, setDNT] = useState(undefined);
  const [result, setResult] = useState(null);

  useEffect(() => {
    setDNT(navigator.doNotTrack);
    if (navigator.doNotTrack === '1') {
      setResult(true);
    } else {
      setResult(false);
    }
  }, []);

  return (
    <Layout>
      <h1>Navigator.doNotTrack</h1>
      <p>
        The <code>navigator.doNotTrack</code> property returns the user's Do Not Track setting, which indicates whether
        the user is requesting web sites and advertisers to not track them.
      </p>
      <p>
        The value of the property reflects that of the DNT HTTP header, i.e. values
        of <code>"1"</code>, <code>"0"</code>,
        or <code>undefined</code>.
      </p>

      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Test Case</h3>
          <TestResult result={result}>
            <div className="border-2 p-5">
              <div>
                <code>navigator.doNotTrack = {String(dnt)}</code>
              </div>
            </div>
          </TestResult>
        </div>
      </div>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Navigator.doNotTrack"/>
export default DNTPage;
